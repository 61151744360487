import React from "react"
import { GatsbyLocation } from "local-types"
import styled from "styled-components"
import Layout from "../components/Layout"
import { useSiteMetadata } from "../hooks"
import SectionLayout from "../components/WrapperSections/SectionLayout"
import { Link } from "gatsby"
import { colors, device } from "../styles/constants"
import SEO from "../components/Seo/Seo"

interface NotFoundPageProps {
  location: GatsbyLocation
}

const notfoundMetadata = {
  Title: "Not found - Flipbase",
  Keywords: ["Not found page"],
  Description:
    "We are a software company with the mentality of a pioneer, always trying to be ahead of the market and constantly searching for and developing new technology. Read more about Flipbase.",
  Image: "/seo-image-general.png",
}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = ({
  location,
}): React.ReactElement => {
  const { title: siteTitle } = useSiteMetadata()

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={notfoundMetadata.Title}
        description={notfoundMetadata.Description}
        keywords={notfoundMetadata.Keywords}
        image={notfoundMetadata.Image}
      />
      <SectionLayout>
        <WrapperNotFound>
          <MiniLogoWrapper>
            <img src="/miniLogo.png" alt="mini-logo" />
          </MiniLogoWrapper>
          <h1>Oops, page not found!</h1>
          <h4>Sorry, this page doesn&#39;t exist.</h4>
          <StyledLink to="/">
            <IconArrow src="/arrow.png" />
            <span>Go back to our homepage</span>
          </StyledLink>
        </WrapperNotFound>
      </SectionLayout>
    </Layout>
  )
}

const WrapperNotFound = styled.div`
  margin: 15% auto;
  text-align: center;
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 320px;
  justify-content: flex-start;
  margin: 48px auto;
  img {
    margin-bottom: 0 !important;
  }
  span {
    border-bottom: 1px solid #a62651;
    padding-bottom: 4px;
    font-family: FreightSans;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #231d30;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  @media ${device.mobileL} {
    margin-top: 32px;
  }
`

const IconArrow = styled.img`
  transition: all 0.4s;
  margin-right: 18px;
`

const MiniLogoWrapper = styled.div`
  width: 97px;
  height: 97px;
  background-color: ${colors.mainLight};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  z-index: 10;
  margin-bottom: 32px;
  img {
    margin-bottom: 0;
    margin-left: 6px;
  }
`

export default NotFoundPage
